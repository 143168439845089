import Head from "next/head";
import Image from "next/image";
import dynamic from "next/dynamic";
import styles from "../styles/homepage.module.css";

import { getBestSellerProducts } from "../lib/best-seller-products";
import { getFlashSaleProducts } from "../lib/flash-sale-products";
import { getNewArrivalProducts } from "../lib/new-arrival-products";
import { getWeddingRingProducts } from "../lib/wedding-ring-products";
import { getAttributes } from "../lib/attributes-lib";
import { getArticles } from "../lib/articles-lib";
import { Breakpoint } from "../utils/media-query";
import { useEffect, useState } from "react";
import BannerSkeleton from "../components/skeleton/banner-skeleton";

import Link from "next/link";

const Loading = () => <p>Loading...</p>;

const Banner = dynamic(() => import("../components/homepage/banner/banner"), {
  loading: () => <BannerSkeleton />,
});
const BestSellerProduct = dynamic(
  () => import("../components/homepage/best-seller/best-seller-product"),
  { loading: Loading }
);
const FlashSaleProduct = dynamic(
  () => import("../components/homepage/flash-sale/flash-sale-products"),
  { loading: Loading }
);
const NewArrivalProduct = dynamic(
  () => import("../components/homepage/new-Arrival/new-arrival-product"),
  { loading: Loading }
);
const WeddingRingProduct = dynamic(
  () => import("../components/homepage/wedding-ring/wedding-ring-product"),
  { loading: Loading }
);
const Articles = dynamic(
  () => import("../components/homepage/articles/articles"),
  { loading: Loading }
);

export default function Home() {
  const [isDesktop, isTablet, isMobile] = Breakpoint();
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);

  const [listAttributes, setListAttributes] = useState([]);
  const [bestSeller, setBestSeller] = useState({
    title: "Best Seller",
    url_path: "/all-jewelries",
    products: [],
  });
  const [flashSale, setFlashSale] = useState({
    title: "Flash Sale",
    url_path: "/flash-sale",
    products: [],
  });
  const [newArrival, setNewArrival] = useState({
    title: "New Arrivals",
    url_path: "/whats-new",
    products: [],
  });
  const [weddingRing, setWeddingRing] = useState({
    title: "Wedding Ring",
    url_path: "/product-category/wedding",
    products: [],
  });
  const [articles, setArticles] = useState({
    title: "The Articles",
    url_path: "/magazine",
    articles: [],
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const best_seller = await getBestSellerProducts();
        const { attributes } = await getAttributes();
        const new_arrival = await getNewArrivalProducts();
        const wedding_ring = await getWeddingRingProducts();
        const list_articles = await getArticles();
        const list_flash_sale = await getFlashSaleProducts();
        setBestSeller(best_seller);
        setNewArrival(new_arrival);
        setWeddingRing(wedding_ring);
        setArticles(list_articles);
        setListAttributes(attributes);
        setFlashSale(list_flash_sale);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Head>
        <title>Toko Perhiasan Emas Berlian Online Terpercaya | Aurum Lab</title>
        <meta
          name="description"
          content="Perhiasan emas asli 9K, diamond, dan cincin nikah. Start dari 270rb. Bersertifikat SNI dan BISA dijual kembali. Desain modern, minimalis, GRATIS ongkir ke seluruh Indonesia."
        />
      </Head>
      <main>
        {isMounted && (
          <>
            <Banner isMobile={isMobile} isTablet={isTablet} />
            {!isMobile && (
              <Image
                className={styles.benefits}
                src="https://res.cloudinary.com/aurumlab/image/upload/v1702527237/aurumlab/banner/homepage/SELLING-POINT-M-02-02-001-2048x151_j4ryjz.webp"
                alt="aurum lab benefits"
                width={2048}
                height={151}
                quality={60}
                loading="lazy"
              />
            )}
            <FlashSaleProduct {...flashSale} attributes={listAttributes} />
            <BestSellerProduct {...bestSeller} attributes={listAttributes} />
            <NewArrivalProduct {...newArrival} attributes={listAttributes} />

            {/* Wedding Section  */}
            {isMobile && (
              <>
                <Image
                  className={styles.banner_thumbnail}
                  src="https://res.cloudinary.com/aurumlab/image/upload/v1702527865/aurumlab/banner/homepage/New-Arrival-Aurum-Lab-270923_n0oe5z.webp"
                  loading="lazy"
                  width={1080}
                  height={1080}
                  quality={60}
                  alt="banner wedding"
                />
                <Image
                  className={styles.benefits}
                  src="https://res.cloudinary.com/aurumlab/image/upload/v1702527284/aurumlab/banner/homepage/SELLING-POINT-MOBILE-scaled_xehewu.webp"
                  alt="aurum lab benefits"
                  width={1080}
                  quality={60}
                  height={248}
                  loading="lazy"
                />
              </>
            )}
            <WeddingRingProduct {...weddingRing} />

            <Articles {...articles} />

            <Link href="/the-brand" className="no-after">
              {!isMobile ? (
                <Image
                  className={styles.banner_about}
                  src="https://res.cloudinary.com/aurumlab/image/upload/v1702528428/aurumlab/banner/homepage/Whats-Inside-Our-Lab-d2_knoyy2.jpg"
                  loading="lazy"
                  width={1920}
                  height={400}
                  quality={60}
                  alt="banner about"
                />
              ) : (
                <Image
                  className={styles.banner_about}
                  src="https://res.cloudinary.com/aurumlab/image/upload/v1702528428/aurumlab/banner/homepage/Whats-Inside-Our-Lab-webp-600x600_iyad7k.webp"
                  loading="lazy"
                  quality={60}
                  width={1080}
                  height={1080}
                  alt="banner about"
                />
              )}
            </Link>
          </>
        )}
      </main>
    </>
  );
}
